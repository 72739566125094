import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HTMLContent from "../components/HtmlContent";
import Gallery from "../components/Gallery";

export const ProductPageTemplate = ({
  image,
  title,
  content,
  useHero,
  displayTitle,
}) => (
  <div className="content">
    {useHero && (
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}
      >
        {displayTitle && (
          <div
            style={{
              display: "flex",
              height: "120px",
              justifyContent: "space-around",
              alignItems: "left",
              flexDirection: "column",
            }}
            className="hero-box has-text-white has-text-centered"
          >
            <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-white">
              {title}
            </h1>
          </div>
        )}
      </div>
    )}
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column">
            <HTMLContent className="content" content={content} />
          </div>
        </div>
      </div>
    </section>
  </div>
);

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  html: PropTypes.string,
  useHero: PropTypes.bool,
  useHero: PropTypes.bool,
};

const ProductPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  const {
    pageTitle,
    keywords,
    description,
    useHero,
    displayTitle,
    gallery,
  } = frontmatter;

  return (
    <Layout
      title={pageTitle}
      pageKeywords={keywords}
      pageDescription={description}
    >
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        content={html}
        useHero={useHero}
        displayTitle={displayTitle}
      />
      {gallery ? (
        <Gallery images={gallery.images} columns={gallery.columns} />
      ) : (
        false
      )}
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query Product($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageTitle
        useHero
        displayTitle
        title
        keywords
        description
        gallery {
          columns
          images {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 640
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alt
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
