import React from "react";
import PropTypes from "prop-types";
import GalleryItem from "../components/GalleryItem";
import { Link } from "gatsby";

const Gallery = ({ images, columns }) => {
  const columnStyle = {
    column: true,
    "is-4": columns === 3,
    "is-3": columns === 4,
    "is-6": columns === 2,
  };
  return (
    <div className="container">
      <h3 className="is-size-3 has-text-weight-bold">Bildgalleri</h3>
      <div className="columns is-multiline">
        {images.map((item) => (
          <div key={item.alt} className="column is-4">
            <GalleryItem image={item.image} alt={item.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

Gallery.propTypes = {
  columns: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    })
  ),
};

export default Gallery;
