import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link } from "gatsby";

const GalleryItem = ({ image, alt }) => (
  <div
    className="card"
    style={{
      borderRadius: "4px",
      border: "1px solid #e8e8e8",
      overflow: "hidden",
    }}
  >
    <div className="card-content is-hcentered" style={{ minHeight: "20em" }}>
      <PreviewCompatibleImage image={image} alt={alt} />
    </div>
  </div>
);

GalleryItem.propTypes = {
  path: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
};

export default GalleryItem;
